import { SET_CURRENT_USER, USER_LOADING, RESET_PASSWORD } from '../actions/types';

// const isEmpty = require('is-empty');

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  resetSuccess: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: action.payload,
        user: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case RESET_PASSWORD :
      return {
        ...state,
        resetSuccess: true
      }
    default:
      return state;
  }
}
