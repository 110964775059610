import {
  GET_VISITORS,
  GET_MY_VISITORS,
  ADD_VISITOR,
  GET_VISITOR,
  GET_VISITORS_BY_DATE,
} from '../actions/types';

const initialState = {
  visitors: [],
  myVisitors: [],
  singleVisitor: null,
  postSuccess: false,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_VISITORS:
      return {
        ...state,
        visitors: action.payload,
      };
    case GET_MY_VISITORS:
      return {
        ...state,
        myVisitors: action.payload,
      };
    case GET_VISITORS_BY_DATE:
      return {
        ...state,
        myVisitors: action.payload,
      };
    case GET_VISITOR:
    
      return {
        ...state,
        singleVisitor: action.payload,
      };
    case ADD_VISITOR:
      return {
        ...state,
        postSuccess: true,
      };
    default:
      return state;
  }
}
