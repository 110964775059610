import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signinUser, resetPassword } from '../../actions/authActions';
import { Redirect } from 'react-router-dom';


class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      currentPassword: '',
      password: '',
      password2: '',
      errors: {},
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard

  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    var strongPassword = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");

    const { password, password2, currentPassword, username } = this.state;

    if (username === '' || password === '' || currentPassword === '' || password2 === '') {
      this.setState({ errors: {"username":"Please enter all fields"}});
    } else if (password !== password2){
        this.setState({ error: {"password": "Password doesnot match"}})
    } else {
      
      const userData = {
        username,
        currentPassword,
        password2,
        password,
      };
      let regex = new RegExp('^[a-zA-Z0-9_-]{3,15}$');
      if (regex.test(username)) {
        this.setState({ error: '' });
        this.props.resetPassword(userData);
      } else {
        this.setState({ error: 'Invalid username' });
      }
    }
  };

  render() {
    const { errors } = this.state;
    if (this.props.auth.resetSuccess) {
        return <Redirect to="/dashboard" />;
    } else {
        return (
            <section className="app-landing-wrapper mt-4">
              <div className="app-landing">
                <div className="auth-wrapper">
                  <div className="auth-content">
                    <div className="auth-bg">
                      <span className="r" />
                      <span className="r s" />
                      <span className="r s" />
                      <span className="r" />
                    </div>
                    <form className="card">
                      <div className="card-body text-center">
                        <div className="mb-4">
                          <i className="feather icon-unlock auth-icon" />
                        </div>
                        <h3 className="mb-4">Login</h3>
                        <div className="input-group mb-3">
                          <input
                            placeholder="Enter username"
                            onChange={this.onChange}
                            value={this.state.username}
                            type="text"
                            name="username"
                            className="form-control"
                          />
                          <span className="red-text">{errors.username}</span>
                        </div>
                        <div className="input-group mb-4">
                          <input
                            placeholder="Enter current password"
                            onChange={this.onChange}
                            value={this.state.currentPassword}
                            type="password"
                            name="currentPassword"
                            className="form-control"
                          />
                          <span className="red-text">{errors.password}</span>
                        </div>
                        <div className="input-group mb-4">
                          <input
                            placeholder="Enter new password"
                            onChange={this.onChange}
                            value={this.state.password}
                            type="password"
                            name="password"
                            className="form-control"
                          />
                          <span className="red-text">{errors.password}</span>
                        </div>
                        <div className="input-group mb-4">
                          <input
                            placeholder="Confirm new password"
                            onChange={this.onChange}
                            value={this.state.password2}
                            type="password"
                            name="password2"
                            className="form-control"
                          />
                          <span className="red-text">{errors.password}</span>
                        </div>
                        <button
                          className="btn btn-primary shadow-2 mb-4"
                          onClick={this.onSubmit}>
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
        );
    }
    
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { signinUser, resetPassword })(ResetPassword);

