import React from 'react';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class ContactSection extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-light" id="contact">
          <div className="container">
            <div className="row">
              <Col lg="12">
                <div className="title-heading mb-5">
                  <h3 className="text-dark mb-1 font-weight-light text-uppercase">
                    Get in touch
                  </h3>
                  <div className="title-border-simple position-relative"></div>
                </div>
              </Col>
            </div>

            <Row>
              <Col lg="12">
                <div className="contact-box p-5">
                  <Row>
                    <Col lg="8" md="6">
                      <div className="mb-5 about_us">
                        <p className="text-uppercase text-dark footer-title mb-4">
                          About Us
                        </p>
                        <p className="text-muted f-14">
                          We are Singapore based manufacturers of temperature
                          screening solutions integrated with IoT. We set-up
                          this company with the idea of developing innovative
                          contact-less temperature screening solutions to
                          business owners in Singapore to sail through this
                          worst pandemic of the century. Our continuous
                          improvement strategies drove us to integrate the safe
                          entry check-in, door access control and many more
                          features to our device. We strive to strengthen the
                          safety and well-being of our customers by providing
                          contact-less and cost-effective solutions for
                          temperature screening and data management.
                        </p>
                      </div>
                    </Col>

                    <div className="col-lg-4 col-md-6">
                      <div className="contact-cantent p-3">
                        <div className="contact-details">
                          <div className="float-left contact-icon mr-3 mt-2">
                            <i className="mdi mdi-headphones text-muted h5"></i>
                          </div>
                          <div className="app-contact-desc text-muted pt-1">
                            <p className="mb-0 info-title f-13">Call :</p>
                            <p className="mb-0 f-13">
                              +65 96724975 <span>+65 82263124</span>
                            </p>
                          </div>
                        </div>

                        <div className="contact-details mt-2">
                          <div className="float-left contact-icon mr-3 mt-2">
                            <i className="mdi mdi-email-outline text-muted h5"></i>
                          </div>
                          <div className="app-contact-desc text-muted pt-1">
                            <p className="mb-0 info-title f-13">Email :</p>
                            <p className="mb-0 f-13">
                              <a
                                href="mailto:sales@isinginfotech.sg?subject=Blog Feedback"
                                className="text-muted">
                                sales@isinginfotech.sg
                              </a>
                            </p>
                          </div>
                        </div>

                        <div className="contact-details mt-2">
                          <div className="float-left contact-icon mr-3 mt-2">
                            <i className="mdi mdi-map-marker text-muted h5"></i>
                          </div>
                          <div className="app-contact-desc text-muted pt-1">
                            <p className="mb-0 info-title f-13">Location :</p>
                            <p className="mb-0 f-13">
                              <Link to="" className="text-muted">
                                7030 Ang Mo Kio Avenue 5 #08-05, Northstar @ AMK
                                Singapore 569880
                              </Link>
                            </p>
                          </div>
                        </div>

                        <div className="follow mt-4">
                          <h4 className="text-dark mb-3">Follow</h4>
                          <ul className="follow-icon list-inline mt-32 mb-0">
                            <li className="list-inline-item f-15">
                              <a
                                href="https://www.facebook.com/isinginfotech/"
                                className="social-icon text-muted"
                                target="_blank">
                                <i className="mdi mdi-facebook"></i>
                              </a>
                            </li>
                            &nbsp;
                            <li className="list-inline-item f-15">
                              <a
                                href="https://www.linkedin.com/company/ising-infotech-pte-ltd/"
                                className="social-icon text-muted"
                                target="_blank">
                                <i className="mdi mdi-linkedin"></i>
                              </a>
                            </li>
                            &nbsp;
                            <li className="list-inline-item f-15">
                              <a
                                href="https://wa.me/6596724975"
                                className="social-icon text-muted">
                                <i
                                  className="mdi mdi-whatsapp"
                                  target="_blank"></i>
                              </a>
                            </li>
                            &nbsp;
                          </ul>
                        </div>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default ContactSection;
