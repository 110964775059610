import axios from 'axios';

import {
  ADD_VISITOR,
  GET_VISITORS,
  GET_MY_VISITORS,
  GET_VISITORS_BY_DATE,
  GET_VISITOR,
  ADD_COMMENT,
} from './types';

// create visitor
export const addVisitor = visitorData => dispatch => {
  axios
    .post('/api/visitors/create', visitorData)
    .then(res => {
      dispatch({ type: ADD_VISITOR, payload: res.data });
    })
    .catch(err => console.log(err));
};

// Get visitors
export const getVisitors = () => dispatch => {
  axios
    .get('/api/visitors/all')
    .then(res => {
      dispatch({
        type: GET_VISITORS,
        payload: res.data,
      });
    })
    .catch(err =>
      dispatch({
        type: GET_VISITORS,
        payload: null,
      })
    );
};

// Get My visitors
export const getMyVisitors = user => dispatch => {
  axios
    .post('/api/visitors/my-visitors', user)
    .then(res => {
      dispatch({
        type: GET_MY_VISITORS,
        payload: res.data,
      });
    })
    .catch(err =>
      dispatch({
        type: GET_MY_VISITORS,
        payload: null,
      })
    );
};

// Get Visitors by date
export const getVisitorsByDate = date => dispatch => {
  axios
    .post('/api/visitors/get-visitors-by-date', date)
    .then(res => {
      dispatch({
        type: GET_VISITORS_BY_DATE,
        payload: res.data,
      });
    })
    .catch(err =>
      dispatch({
        type: GET_MY_VISITORS,
        payload: null,
      })
    );
};

// // Get Single visitor
// export const getSingleTopic = topicData => dispatch => {
//   axios
//     .post('/api/topics/single', topicData)
//     .then(res => {
//       console.log(res.data);
//       dispatch({
//         type: GET_TOPIC,
//         payload: res.data,
//       });
//     })
//     .catch(err =>
//       dispatch({
//         type: GET_TOPIC,
//         payload: null,
//       })
//     );
// };
