import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

class FeatureSection extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="section bg-features bg-light" id="features">
          <div className="container">
            <Row>
              <Col className="col-lg-12">
                <div className="title-heading mb-5">
                  <h3 className="text-dark mb-1 font-weight-light text-uppercase">
                    Our Features
                  </h3>
                  {/* <div className="title-border-simple position-relative">CTMI is developed in singapore by a bunch of engineers sighting the inabilities of current temperature monitoring devices available in Singapore. We aim to help local business sail through this high tides. </div> */}
                </div>
              </Col>
            </Row>
            <Row className="align-items-center flex-lg-row-reverse">
              <Col lg="6">
                <div className="features-img mt-32">
                  <img
                    src="images/features-img/1.png"
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="features-content">
                  <div className="features-icon">
                    <i className="pe-7s-shield text-primary"></i>
                  </div>
                  <h4 className="font-weight-normal text-dark mb-3 mt-4">
                    Specifically for Singapore
                  </h4>
                  <p className="text-muted f-14">
                    CTMI is developed in Singapore by a bunch of engineers
                    sighting the inabilities of current temperature monitoring
                    devices available in Singapore. We aim to help local
                    business sail through this high tides.{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="section bg-features">
          <div className="container">
            <Row className="align-items-center">
              <Col lg="6">
                <div className="features-img">
                  <img
                    src="images/features-img/2.png"
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="features-content mt-32">
                  <div className="features-icon">
                    <i className="pe-7s-stopwatch text-primary"></i>
                  </div>
                  <h4 className="font-weight-normal text-dark mb-3 mt-4">
                    Accurate temperature monitoring
                  </h4>
                  <p className="text-muted f-14">
                    CTMI’s medical grade sensors has a temperature measuring
                    accuracy of +/-0.2 degree celcius with a resolution of 0.02
                    degree celcius. Making it a perfect solution for body
                    temperature screening.{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="section bg-features bg-light">
          <div className="container">
            <Row className="align-items-center flex-lg-row-reverse">
              <Col lg="6">
                <div className="features-img mt-40">
                  <img
                    src="images/features-img/3.png"
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="features-content">
                  <div className="features-icon">
                    <i className="pe-7s-attention text-primary"></i>
                  </div>
                  <h4 className="font-weight-normal text-dark mb-3 mt-4">
                    NRIC or Company ID Verification
                  </h4>
                  <p className="text-muted f-14">
                    As most competitors uses facial recognition features to
                    identify users, CTMI works with either NRIC or Company ID
                    cards. It also works with RFID and Barcodes. That makes it
                    easier to keep track of the data with a high degree of
                    identification.{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="section bg-features">
          <div className="container">
            <Row className="align-items-center">
              <Col lg="6">
                <div className="features-img">
                  <img
                    src="images/features-img/4.png"
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="features-content mt-32">
                  <div className="features-icon">
                    <i className="pe-7s-config text-primary"></i>
                  </div>
                  <h4 className="font-weight-normal text-dark mb-3 mt-4">
                    Safe entry integration
                  </h4>
                  <p className="text-muted f-14">
                    CTMI is capable of integrating Safe entry by NRIC scanning.
                    The user will undergo temperature measurement first and for
                    all those who are normal, will have the option to do safe
                    entry check-in by scanning their NRIC.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="section bg-features bg-light">
          <div className="container">
            <Row className="align-items-center flex-lg-row-reverse">
              <Col lg="6">
                <div className="features-img mt-40">
                  <img
                    src="images/features-img/5.png"
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="features-content">
                  <div className="features-icon">
                    <i className="pe-7s-display1 text-primary"></i>
                  </div>
                  <h4 className="font-weight-normal text-dark mb-3 mt-4">
                    Travel and health self declaration
                  </h4>
                  <p className="text-muted f-14">
                    CTMI can be used for Health and Travel self declaration,
                    which is necessary for visitors entering your premises.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>

        <section className="section bg-features">
          <div className="container">
            <Row className="align-items-center">
              <Col lg="6">
                <div className="features-img">
                  <img
                    src="images/features-img/6.png"
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="features-content mt-32">
                  <div className="features-icon">
                    <i className="pe-7s-server text-primary"></i>
                  </div>
                  <h4 className="font-weight-normal text-dark mb-3 mt-4">
                    Fast: Less than 3 secs per user
                  </h4>
                  <p className="text-muted f-14">
                    CTMI only requires less than 3 seconds per user. Thats 1200
                    scanning per hour. Making it a perfect solution for places
                    with high traffic.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="section bg-features bg-light">
          <div className="container">
            <Row className="align-items-center flex-lg-row-reverse">
              <Col lg="6">
                <div className="features-img mt-40">
                  <img
                    src="images/features-img/img-2.png"
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="features-content">
                  <div className="features-icon">
                    <i className="pe-7s-add-user text-primary"></i>
                  </div>
                  <h4 className="font-weight-normal text-dark mb-3 mt-4">
                    UI Customisation
                  </h4>
                  <p className="text-muted f-14">
                    CTMI’s User interface is fully customisable to meet your
                    company’s needs/policies. You can utilise 24” screen space
                    to run ads or custom message like company’s travel
                    declaration policy or simply a greeting for visitors.{' '}
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="section bg-features bg-light">
          <div className="container">
            <Row className="align-items-center">
              <Col lg="6">
                <div className="features-img mt-40">
                  <img
                    src="images/features-img/4.png"
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="features-content">
                  <div className="features-icon">
                    <i className="pe-7s-add-user text-primary"></i>
                  </div>
                  <h4 className="font-weight-normal text-dark mb-3 mt-4">
                    Door access integration
                  </h4>
                  <p className="text-muted f-14">
                    CTMI is capable of integrating restricted access / turnstile
                    integration.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
        <section className="section bg-features bg-light">
          <div className="container">
            <Row className="align-items-center flex-lg-row-reverse">
              <Col lg="6">
                <div className="features-img mt-40">
                  <img
                    src="images/features-img/7.png"
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
              <Col lg="6">
                <div className="features-content">
                  <div className="features-icon">
                    <i className="pe-7s-add-user text-primary"></i>
                  </div>
                  <h4 className="font-weight-normal text-dark mb-3 mt-4">
                    Around the globe Live data accessibility{' '}
                  </h4>
                  <p className="text-muted f-14">
                    All the recorded temperature data will be stored in cloud
                    database. Device owners will have a User ID and Password to
                    access the live data from the device from any part of the
                    world. All you need is an internet connected device. All
                    these data can be exported to Excel in just a click. CTMI is
                    also capable of sending data from the device to your own
                    website via API integration.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default FeatureSection;
