import React, { Component } from 'react';

import { connect } from 'react-redux';

import { addVisitor } from '../../actions/visitorActions';
import { setCurrentUser } from '../../actions/authActions';


import { Formik, Form, ErrorMessage, Field } from 'formik';

import './style.scss';

class VisitorForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      description: '',
      error: '',
      postSuccess: false,
    };
    this.onChange = this.onChange.bind(this);
    // this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    await this.props.setCurrentUser();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.visitor !== prevProps.visitor) {
      this.setState({
        postSuccess: this.props.visitor.postSuccess,
      });
    }
  }

  // onSubmit(e) {
  //   e.preventDefault();

  //   if (this.state.temperature === '') {
  //     this.setState({ error: 'Please enter all fields' });
  //   } else {
  //     this.setState({ error: '' });
  //     const { user } = this.props.auth;

  //     const newVisitor = {
  //       temperature: parseFloat(this.state.temperature),
  //       user,
  //     };

  //     this.props.addVisitor(newVisitor);
  //     this.setState({ temperature: '' });
  //   }
  // }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {

    return (
      <div className="auth-wrapper">
        <div className="auth-content">
          <div className="auth-bg">
            <span className="r" />
            <span className="r s" />
            <span className="r s" />
            <span className="r" />
          </div>
          <Formik
            initialValues={{ name: '', phonenumber: '' }}
            validate={values => {
              const errors = {};
              let nameValidator = new RegExp("^[a-zA-Z ]*$")
              let phonenumberValidator = new RegExp("^[0-9]{8}$")
              if (!values.name) {
                errors.name = 'Required';
              } else if ( !nameValidator.test(values.name)) {
              
                errors.name = 'Invalid Name! Please check!';
              } 

              if (!values.phonenumber) {
                errors.phonenumber = 'Required';
              } else if ( !phonenumberValidator.test(values.phonenumber)) {
                errors.phonenumber = 'Invalid phone number';
              }

              return errors;
            }}
            onSubmit={(values, { setSubmitting }) => {

              const { user, temperature } = this.props.match.params;

              setTimeout(() => {
                setSubmitting(false);
              }, 400);

              const newVisitor = {
                temperature: parseFloat(temperature),
                user,
                name: values.name,
                phonenumber: values.phonenumber
              };
        
              this.props.addVisitor(newVisitor);
            }}
          >
            {this.state.postSuccess ? (
              <div className="card">
                <div className="card-body">
                  <h1>
                    Thank you! 
                  </h1> 
                  <h3>Enjoy your visit!</h3>
                </div>
              </div>
            ):(
              <Form className="card visitor-form">
                  <div className="card-body text-center">
                    <h3 className="mb-4">Visitor Entry Form</h3>
                    <div className="input-group mb-3 flex-column">
                      <label>Full Name</label>
                      <Field className="form-control" type="text" name="name" id="name" placeholder="Enter your Full Name"/>
                      <ErrorMessage name="name" component="div" />
                    </div>
                    
                    <div className="input-group mb-4 flex-column">
                      <label>Phone Number</label>
                      <Field className="form-control" type="text" name="phonenumber" id="phonenumber" placeholder="Enter your Phone Number" />
                      <ErrorMessage name="phonenumber" component="div" />
                    </div>
                    
                    <button type="submit"  className="btn btn-primary shadow-2">
                      Submit
                    </button>
                  </div>           
                </Form>
            )}
            
          </Formik>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  visitor: state.visitor,
});

export default connect(mapStateToProps, { setCurrentUser, addVisitor })(
  VisitorForm
);
