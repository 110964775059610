import React, { Component } from 'react';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { Link } from 'react-router-dom';
// import { Navbar, Nav } from 'react-bootstrap';

// import ScrollspyNav from '../scrollSpy';
//import './style.scss';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openMenu: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.onLogoutClick = this.onLogoutClick.bind(this);
  }

  toggleMenu() {
    this.setState({
      openMenu: !this.state.openMenu,
    });
  }

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };

  componentDidMount() {}

  render() {
    const { isAuthenticated, user } = this.props.auth;

    const authLinks = (
      <a
        className="login"
        onClick={this.onLogoutClick}
        style={{ cursor: 'pointer' }}>
        Logout
      </a>
    );


    return (
      <React.Fragment>
        <nav
          id="main_navbar"
          className="navbar navbar-expand-lg fixed-top  sticky navbar-light navbar-custom nav-sticky navbar-bg">
          <div className="container">
            <a className="navbar-brand logo" href="/">
              <img src="images/logo.png" alt="" height="50" />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarCollapse"
              aria-controls="navbarCollapse"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <i className="mdi mdi-menu"></i>
            </button>

            <div className="collapse navbar-collapse" id="navbarCollapse">
              <ul className="navbar-nav ml-auto navbar-center" id="mySidenav">
                <li className="nav-item active">
                  <a href="/#home" className="nav-link">
                    Home
                  </a>
                </li>
                {/* <li className="nav-item"><a href="#about" className="nav-link">About</a></li> */}
                {/* <li className="nav-item">
                  <a className="nav-link" href="/dashboard">
                    DASHBOARD
                  </a>
                </li> */}

                <li className="nav-item">
                  <a href="/#features" className="nav-link">
                    Features
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/#services" className="nav-link">
                    Services
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/#clients" className="nav-link">
                    Clients
                  </a>
                </li>
                <li className="nav-item">
                  <a href="/#contact" className="nav-link">
                    Contact us
                  </a>{' '}
                </li>
              </ul>

              {isAuthenticated ? (
                <React.Fragment>{authLinks}</React.Fragment>
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(Header);
