import React, { Component } from 'react';

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Tabs,
  Tab,
  Form,
  Button,
  Image
} from 'react-bootstrap';

import './style.scss';


export default class Homepage extends Component {
  render() {
    return (
      <div className="homepage">
        <div className="banner-wrapper">
          <div className="banner-content">
            <div className="banner-bg">
              <span className="r" />
              <span className="r s" />
              <span className="r s" />
              <span className="r" />
            </div>
            <Container fluid>
              <Row>
                <Col md={12}>
                  <h1>Contactless Temperature Monitoring with IOT (CTMI)</h1>
                  <p>CTMI is a device which Measures temperature, Records it in the database with name,date, time and temperature. CTMI is much more accurate than expensive thermal cameras ands aves all data in the database. It compares the data to see if there are any raise or deviation in the temperature and notifies the user if the temperature recorded is high.All this is done with Zerocontact with the device.</p>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <section>
          <Container className="p-4" >
            <Row className="justify-content-center">
              <h1 className="mt-4 mb-4 p-2">Why CTMI?</h1>
            </Row>
            <Row>
              <Col md={4} xl={4}>
      
                <div className="row d-flex feature-item" >
                  <div className="col-12">
                    <Image src="/assets/images/time.svg" />
                  </div>
                  <div className="col-12">
                    <h3>Less than 3s per user</h3>
                    <p className="m-b-0">CTMI only take less than 3 seconds to serve a user and works all day long with zero manpower support. It is easy to maintain, costs very less compared to traditional IR Guns and Thermal cameras. CTMI is 100% customizable according to each workplaces.</p>
                  </div>
                </div>
       
              </Col>
              <Col md={4} xl={4}>
                  <div className="row d-flex feature-item">
                    <div className="col-12">
                      <Image src="/assets/images/notification.svg" />
                    </div>
                    <div className="col-12">
                      <h3>Notifies on deviation</h3>
                      <p className="m-b-0">CTMI notifies the owner if a temperature deviation is observed in any user.</p>
                    </div>
                  </div>
              </Col>
              <Col md={4} xl={4}>
                <div className="row d-flex feature-item">
                  <div className="col-12">
                    <Image src="/assets/images/features.svg" />
                  </div>
                  <div className="col-12">
                    <h3>More add on features</h3>
                    <p className="m-b-0">CTMI is 100% customisable and featuring that can be added is infinite starting from an auto hand sanitizer dispenser to face recognition and many more</p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          
        </section>
        
      </div>
    )
  }
}
