import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import jwt_decode from 'jwt-decode';

import { SET_CURRENT_USER, GET_ERRORS, USER_LOADING, RESET_PASSWORD } from './types';

// export const setCurrentUser = () => dispatch => {
//   axios
//     .get('/api/users/current_user')
//     .then(res => {
//       dispatch({
//         type: SET_CURRENT_USER,
//         payload: res.data,
//       });
//     })
//     .catch(err => {
//       console.log(err.response);
//     });
// };

export const setCurrentUser = decoded => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};

export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};

// export const logoutUser = () => dispatch => {
//   axios
//     .get('/api/users/logout')
//     .then(res => {
//       res.send('Logout Succesfull');
//     })
//     .catch(err => {
//       console.log(err.response);
//     });
// };

// Log user out
export const logoutUser = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem('jwtToken');
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};

// export const createUser = userData => dispatch => {
//   axios
//     .post('/api/users/signup', userData)
//     .then(res => {
//       dispatch({
//         type: SET_CURRENT_USER,
//         payload: res.data,
//       });
//     })
//     .catch(err => {
//       console.log(err);
//     });
// };

export const signinUser = userData => dispatch => {
  axios
    .post('/api/users/signin', userData)
    .then(res => {
      // Save to localStorage

      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem('jwtToken', token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);

      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};



export const resetPassword = (userData, history) => dispatch => {
  axios
    .post('/api/users/reset-password', userData)
    .then(res => {
      // Set current userc
      dispatch({
        type: RESET_PASSWORD,
        payload: res.data.resetPassword,
      })
    })
    .catch(err =>
      console.log(err)
    );
};


// export const signinUser = userData => dispatch => {
//   axios.post('/api/users/signin', userData)
//   .then(res => {
//     dispatch({
//       type: SET_CURRENT_USER,
//       payload: res.data,
//     });
//   })
//   .catch(err => {
//     console.log(err);
//   })
// };

export const createUser = (userData, history) => dispatch => {
  axios
    .post('/api/users/signup', userData)
    .then(res => history.push('/dashboard'))
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
