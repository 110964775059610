import React from 'react';
import 'react-modal-video/scss/modal-video.scss';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
// import lottie from 'lottie-web';

class Home1 extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    // lottie.loadAnimation({
    //   container: document.getElementById('lottie-animation'), // the dom element that will contain the animation
    //   renderer: 'svg',
    //   loop: true,
    //   autoplay: true,
    //   path: '/images/anime1.json', // the path to the animation json
    // });
  }

  render() {
    return (
      <React.Fragment>
        <section className="section home-2-bg" id="home">
          <div className="home-center">
            <div className="home-desc-center">
              <div className="container">
                <Row className="align-items-center justify-content-center">
                  <Col lg="6">
                    <div className="mt-40 home-2-content">
                      <h1 className="text-white font-weight-normal display-8 mb-0">
                        Introducing CTMI
                      </h1>
                      <h3 className="text-white mt-4 mb-0 mb-0">
                        All in one Temperature Monitoring Solution
                      </h3>
                      <p className="text-white mt-4 font-weight-normal mb-0">
                        CTMI is a contactless temperature monitoring system
                        which requires users to tap their Company ID card or
                        NRIC to keep track of their temperature data. All data
                        will be saved in a database or govt Safe entry system.
                        The data can be accessed by the device owners using a PC
                        or a mobile phone. The data can also be exported to
                        excel.
                        <br />
                        <br /> This device eliminates the need of multiple staff
                        for temperature screening and keeps computerised data of
                        all users, which can save operation cost for businesses.
                        It also reduces the risk of exposure for the security
                        officers by eliminating the need of screening
                        individuals manually. Scroll down to know more
                      </p>
                      <div className="mt-5">
                        {/* <Link to="#" className="btn btn-custom mr-4">
                          Learn More
                        </Link> */}
                      </div>
                    </div>
                  </Col>

                  {/* <Col lg="7">
                    <div
                      className="mt-40 home-2-content position-relative"
                      id="lottie-animation"></div>
                  </Col> */}
                </Row>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
export default Home1;
