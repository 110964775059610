// Root Reducer
import { combineReducers } from 'redux';
import authReducer from './authReducer';
import visitorReducer from './visitorReducer';
import errorReducer from './errorReducer';

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  visitor: visitorReducer,
});
