import React from 'react';

import Navbar from './components/Navbar';
import Home1 from './components/Home1';
import ServiceSection from './components/ServiceSection';
import FeatureSection from './components/FeatureSection';
import ClientSection from './components/ClientSection';
import ContactSection1 from './components/ContactSection1';
import Footer from './components/Footer';
import FooterAlt from './components/FooterAlt';

class Index1 extends React.Component {
  componentDidMount() {
    document.getElementById('main_navbar').classList.remove('nav-sticky');
    document.getElementById('main_navbar').classList.remove('navbar-bg');
  }

  render() {
    return (
      <React.Fragment>
        {/* preloader */}

        {/* HomeSection Menu */}
        <Home1 />

        {/* AboutSection Menu */}
        {/* <AboutSection /> */}

        {/* FeatureSection Menu */}
        <FeatureSection />

        {/* ServiceSection Menu */}
        <ServiceSection />

        {/* ClientSection Menu */}
        <ClientSection />

        {/* ContactSection Menu */}
        <ContactSection1 />

        {/* Footer Menu */}
        {/*<Footer />*/}

        {/* FooterAlt Menu */}
        <FooterAlt />
      </React.Fragment>
    );
  }
}

export default Index1;
