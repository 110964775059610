import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { signinUser } from '../../actions/authActions';

class Login extends Component {
  constructor() {
    super();
    this.state = {
      username: '',
      password: '',
      generalErrors: '',
      usernameErrors: '',
      passwordErrors: '',
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push('/dashboard');
    }

    // if (nextProps.errors) {
    //   this.setState({
    //     d: nextProps.validationErrors,
    //   });
    // }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    if (this.state.username === '' || this.state.password === '') {
      this.setState({ generalErrors: 'Please enter all fields' });
    } else {
      this.setState({ generalErrors: '' });
      const userData = {
        username: this.state.username,
        password: this.state.password,
      };
      let regex = new RegExp('^[a-zA-Z0-9_-]{3,15}$');
      if (regex.test(this.state.username)) {
        this.setState({ error: '' });
        this.props.signinUser(userData);
      } else {
        this.setState({ usernameErrors: 'Invalid username' });
      }
    }
  };

  render() {
    const { errors } = this.props;
    return (
      <section className="app-landing-wrapper mt-4">
        <div className="app-landing">
          <div className="auth-wrapper">
            <div className="auth-content">
              <div className="auth-bg">
                <span className="r" />
                <span className="r s" />
                <span className="r s" />
                <span className="r" />
              </div>
              <form className="card">
                <div className="card-body text-center">
                  <div className="mb-4">
                    <i className="feather icon-unlock auth-icon" />
                  </div>
                  <h3 className="mb-4">Login</h3>
                  <div className="input-group mb-3">
                    <input
                      placeholder="Enter username"
                      onChange={this.onChange}
                      value={this.state.username}
                      type="text"
                      name="username"
                      className="form-control"
                    />
                    <span className="red-text error-message">
                      {errors.usernotfound}
                    </span>
                    <span className="red-text error-message">
                      {this.state.usernameErrors}
                    </span>
                    <span className="red-text error-message">
                      {this.state.generalErrors}
                    </span>
                  </div>
                  <div className="input-group mb-4">
                    <input
                      placeholder="Enter password"
                      onChange={this.onChange}
                      value={this.state.password}
                      type="password"
                      name="password"
                      className="form-control"
                    />
                    <span className="red-text error-message">
                      {errors.passwordincorrect}
                    </span>
                    <span className="red-text error-message">
                      {this.state.generalErrors}
                    </span>
                  </div>
                  <button
                    className="btn btn-primary shadow-2 mb-4"
                    onClick={this.onSubmit}>
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { signinUser })(Login);
