import React from 'react';
import { Row, Col, Button } from 'reactstrap';
// import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import RBCarousel from 'react-bootstrap-carousel';

const styles = { height: 400, width: '50%' };

class ClientSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      autoplay: true,
    };
  }
  onSelect = (active, direction) => {
    console.log(`active=${active} && direction=${direction}`);
  };
  visiableOnSelect = active => {
    console.log(`visiable onSelect active=${active}`);
  };
  slideNext = () => {
    this.slider.slideNext();
  };
  slidePrev = () => {
    this.slider.slidePrev();
  };
  goToSlide = () => {
    this.slider.goToSlide(4);
  };
  autoplay = () => {
    this.setState({ autoplay: !this.state.autoplay });
  };
  _changeIcon = () => {
    let { leftIcon, rightIcon } = this.state;
    leftIcon = leftIcon ? undefined : <span className="fa fa-glass" />;
    rightIcon = rightIcon ? undefined : <span className="fa fa-music" />;
    this.setState({ leftIcon, rightIcon });
  };
  render() {
    return (
      <React.Fragment>
        <section className="section bg-clients" id="clients">
          <div className="bg-overlay"></div>
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="title-heading mb-5">
                  <h3 className="text-white mb-1 font-weight-light text-uppercase">
                    Our Clients
                  </h3>
                  <div className="title-border-color position-relative"></div>
                </div>
              </div>
            </div>
            <p className="text-white mb-1 font-weight-light col-lg-12 client-message">
              "CTMI with cloud database and Safe entry integration is just
              amazing. Easy and efficient way to do temperature screening and
              safe entry check-in. This tool is worth every penny"
              <br />
              <span>- Tom (My bartender)</span>
            </p>
          </div>
        </section>
        <div id="client_logo">
          <div id="our_client_logos">
            <img src="images/Client-Logos/1.jpg" />
            <img src="images/Client-Logos/3.jpg" />
            <img src="images/Client-Logos/5.png" />
            <img src="images/Client-Logos/4.jpg" />
            <img src="images/Client-Logos/6.jpg" />
            <img src="images/Client-Logos/7.jpg" />
            <img src="images/Client-Logos/8.jpeg" />
            <img src="images/Client-Logos/9.jpeg" />
            <img src="images/Client-Logos/10.png" />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default ClientSection;
