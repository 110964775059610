export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const ADD_VISITOR = 'ADD_VISITOR';
export const GET_VISITOR = 'GET_VISITOR';
export const GET_VISITORS = 'GET_VISITORS';
export const GET_MY_VISITORS = 'GET_MY_VISITORS';
export const ADD_COMMENT = 'ADD_COMMENT';
export const GET_VISITORS_BY_DATE = 'GET_VISITORS_BY_DATE';
export const GET_ERRORS = 'GET_ERRORS';
export const USER_LOADING = 'USER_LOADING'; 
export const RESET_PASSWORD =  'RESET_PASSWORD';
