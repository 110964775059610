import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import setAuthToken from './utils/setAuthToken';

import { setCurrentUser, logoutUser } from './actions/authActions';

import { Provider } from 'react-redux';
import store from './store';

import PrivateRoute from './components/PrivateRoute';

import Header from './components/Header';
import Landing from './components/Landing';
import Homepage from './components/Homepage';
import Preloader from './components/Preloader';

import VisitorForm from './components/VisitorForm';

import $ from 'jquery';

import './App.scss';
import Signup from './components/Signup';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';
import Index1 from './Index1';

// Check for token to keep user logged in
if (localStorage.jwtToken) {
  // Set auth token header auth
  const token = localStorage.jwtToken;
  setAuthToken(token);
  // Decode token and get user info and exp
  const decoded = jwt_decode(token);
  // Set user and isAuthenticated
  store.dispatch(setCurrentUser(decoded));
  // Check for expired token
  const currentTime = Date.now() / 1000; // to get in milliseconds
  if (decoded.exp < currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to login
    window.location.href = './login';
  }
}

class App extends Component {
  componentDidMount() {
    $('#status').fadeOut();
    $('#preloader')
      .delay(350)
      .fadeOut('slow');
    $('body')
      .delay(350)
      .css({
        overflow: 'visible',
      });
    let headerHeight = document.getElementById('main_navbar').clientHeight;

    let home = document.getElementById('home');
    if (!home) {
      document.getElementsByClassName(
        'app-wrapper'
      )[0].style.marginTop = `${headerHeight + 30}px`;
    }
  }
  render() {
    return (
      <Provider store={store}>
        <Router>
          <Preloader />
          <Header />

          <div className="app-wrapper">
            <div className="App">
              <Route exact path="/" component={Index1} />
              {/* <Route exact path="/signup" component={Signup} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/reset-password" component={ResetPassword} />
              <Route
                exact
                path="/visitor-form/:user/:temperature"
                component={VisitorForm}
              />
              <Switch>
                <PrivateRoute exact path="/dashboard" component={Landing} />
              </Switch> */}
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
